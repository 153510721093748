
					@import './src/sass/variables';
					@import './src/sass/mixins';
				




























































































ol {
	list-style-type: none;
	margin: 0;
	padding: 0;
	max-width: 500px;
}

li {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	background: $color__white;
	font-size: 1.25rem;
	width: 100%;
	border: none;
	box-shadow: $box_shadow__input;
	border-radius: $border_radius;
	padding: 0.5rem 1rem;
	margin-bottom: $default_padding / 2;
	line-height: 1em;
	outline: none !important;
	transition: box-shadow 0.2s ease-out;
	cursor: grab;

	&:active {
		cursor: grabbing;
	}
}

.disabled li {
	cursor: default;
}
